export const environment = {
  production: true,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },

  // TEST
  // baseUrl: 'https://sandbox-apigateway.mypasspoint.com/session/api/v1',
  // baseUrlMerchant: 'https://sandbox-apigateway.mypasspoint.com/merchant/api/v1',
  // baseUrlCheckout: 'https://sandbox-apigateway.mypasspoint.com/passpoint-checkout/api/v1',


  // PRODUCTION
  baseUrl: 'https://apigateway.mypasspoint.com/session/api/v1',
  baseUrlMerchant: 'https://apigateway.mypasspoint.com/merchant/api/v1',
  baseUrlCheckout: 'https://apigateway.mypasspoint.com/passpoint-checkout/api/v1',

  idleTimeInMinutes: 10,
};
